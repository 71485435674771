import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const OnTheSide = () => (
  <Layout>
    <SEO
      title="Building Something on The Side"
      description="Should you build something on the side, what are your chances of success and how long will it take before you can make a living from it?"
    />
    <h1>Building Something on The Side</h1>

    <p>Should you build something on the side, what are your chances of success and how long will it take before you can make a living from it?</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/jLM2EO9.jpg"
        alt="build something on the side"
        description="steel frame under contruction with cranes"
      />
    </div>
    <p>
      Photo by{" "} 
      <a
        href="https://unsplash.com/@danist07"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      Danist Soh
      </a>
      {" "} on Unsplash
    </p>


    <p>If you are asking these questions then you might already have a difficult road ahead of you. Don’t worry though because it’s doable with some consistency and perseverance. It is difficult because the people who have the easiest time working on a project are those who can’t see it any other way.</p>
    <p>They are working on their passion so it doesn’t matter how long it will take, just that it gets done. They aren’t thinking about if it will be successful or not. When it is finished and solves the problem it is supposed to then that’s all the success it needs. Riches are a bonus. Making a living is a tricky one but again, to the passionate it doesn’t really matter.</p>
    <p>Now, to those who live in the land of logic and ask these questions, not out of selfishness, but out of the desire to not waste precious time; you aren’t doing anything wrong.</p>
    <p>Most people who start some kind of business need to think about profit, time and success because these are the things that allow you to get paid for your efforts, pay your staff if you have any and grow your business into what it should be.</p>

    <h2>What Should You Build?</h2>
    <p>The easiest way to stay motivated and have the highest productivity is to build something that matters to you or even solves a problem you have. This way you are far less likely to give up if you get stuck and you will likely solve problems in your head when you are away from your workstation.</p>
    <p>You won’t really need to survey potential clients early on because if it works for you and solves your problem, anyone else with the same problem should see that benefit too. That’s the theory anyway. It's always a good idea to get feedback once you’ve got something built so you can improve the product if necessary.</p>
    <p>In summary, no one can tell you what to build. That’s something you have to decide for yourself. Draw from your own experiences to find something worth working on.</p>

    <h2>What are your chances of success?</h2>
    <p>The possibility of failure is the risk we take when we decide to build anything. You could argue that there is no such thing as failure because you should learn something through your efforts. That way, if you decide to go again, you will have a better chance of success. Let’s not get into failure too much though because it isn’t something you should be focussed on in the beginning. The negatives need to be considered but for a good mindset, it’s important to think about what the future looks like if things go right.</p>
    <p>To have an idea of your success, you can look at similar products to get an idea of how yours would perform. You could make some estimates about how much of an audience your solution could help, then divide that up to a number of people you think you could reach in the first few years. It’s likely that you won’t be able to service your entire target market in the first few years of business so be conservative with your numbers. Then you will need to times that number by the amount of profit you will make on each sale. You’ll need to consider other equations like the cost to acquire a customer and any equipment you have to purchase and maintain.</p>
    <p>The truth is that you can do all the analysis in the world but nothing will prepare you better than taking action. You might fall short of your predictions, you might exceed them. Either way, there are advantages and disadvantages. Plan well, but not too much, then get some work done.</p>

    <h2>Should you even build something on the side?</h2>
    <p>There are negatives and advantages to building on the side. An obvious negative is the lack of time. Most of us have work commitments, which is where the term building on the side comes from, so there’s a large part of your day gone. Then there’s many others of us who have family who we want to spend time with. That can often leave about 2-3 hours of clear time a day. Not very much. But…</p>
    <p>Parkinson’s law describes how a task will take as long as the time given. If you have 10 hours to do something, you might stretch that to fill the 10 hours. Mixing in a few lunch breaks and general unproductive time. If you had 4 hours to do the same task, your productivity would go through the roof and anything not linked to the success of the task would get thrown out the window. I’m sure you can see where I'm going with this.</p>

    <h2>After The Success</h2>
    <p>One of the results of building something is that it might put you in a position where your income drastically increases. This gives you a few choices. If you have built something sustainable and can make money for years to come then you can adjust your life to make it your main source of income. I.e quit your job and focus on your own business.</p>
    <p>Then there’s the option of keeping your job and automating every task in your business so it can run without you. This is entirely possible but an option that is often overlooked. A common choice is to sell the business. If you have built something that produces money, it doesn’t only have value to you, it has real value to someone else too. Get your business valued and if you can find an offer for around that price then sell up.</p>
    <p>Selling the business doesn’t have to be done straight away either. Grow the business and make it a big name, become a leader in your space and the value will increase exponentially. You might even be able to retire off the amount you receive from the sale. Or you could take that money and start again yourself or invest in other companies.</p>
    <p>Then there’s the option of keeping the business for the foreseeable future. There’s nothing wrong with owning something that produces cash reliably. In this fast paced world, don’t feel pressured into making a choice you might regret later. A lifestyle business is one where you make a comfortable living with relatively low effort, this might appeal to you.</p>
    <p>Whatever you want to do, the choice is yours. Appreciate your work and thank yourself for making the jump all that time ago.</p>


    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default OnTheSide
